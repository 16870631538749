import React, { useContext } from 'react';
import { ContainerStyled } from '../styles';
import { CompanyContext } from '../../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import Button from '../../button/button';

interface Props {
  action: () => void;
}

export const UnlockBanner = ({ action }: Props) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          SITE {
            LOCK {
              UNLOCK_BANNER {
                TITLE_1
                TITLE_2
                TITLE_3
                TITLE_4
                BUTTON
              }
            }
          }
        }
      }
    }
  `);
  const { getPublicURL } = useContext(CompanyContext);
  return (
    <ContainerStyled className="w-full mb-16 md:mt-20 text-center md:text-left">
      <div className="relative bg-secondary text-primary-contrast rounded-sm px-2 py-10 md:p-20">
        <img
          src={getPublicURL(`/theme/assets/images/site/account-lock/unlock-frame.svg`)}
          alt="Unlock frame"
          className="hidden md:block absolute right-0 top-0 h-full w-auto"
        />

        <div className="relative max-w-md mx-auto md:mx-0">
          <h4 className="text-4xl mb-8">
            {t(data).SITE.LOCK.UNLOCK_BANNER.TITLE_1}
            <br /> <b>{t(data).SITE.LOCK.UNLOCK_BANNER.TITLE_2}</b> {t(data).SITE.LOCK.UNLOCK_BANNER.TITLE_3}
            <br className="hidden md:block" /> {t(data).SITE.LOCK.UNLOCK_BANNER.TITLE_4}
          </h4>
          <Button color={'secondary'} onClick={action} className="px-12 py-4 leading-none">
            {t(data).SITE.LOCK.UNLOCK_BANNER.BUTTON}
          </Button>
        </div>
      </div>
    </ContainerStyled>
  );
};
