import { Shapes } from '../shapes';
import { ContainerStyled } from '../styles';
import React, { useContext } from 'react';
import { CompanyContext } from '../../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import Button from '../../button/button';

interface Props {
  action: () => void;
}

export const Portrait = ({ action }: Props) => {
  const { t } = useContext(LangContext);

  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          SITE {
            LOCK {
              PORTRAIT {
                TITLE_1
                TITLE_2
                TITLE_3
                DESCRIPTION
                BUTTON
              }
            }
          }
        }
      }
    }
  `);

  const { getPublicURL } = useContext(CompanyContext);
  return (
    <div className="relative md:overflow-hidden text-white pt-20 mb-32 md:mb-16">
      <img
        src={getPublicURL(`/theme/assets/images/site/portrait-bg-mobile.svg`)}
        className="md:hidden absolute top-0 left-0 w-full"
        style={{ minHeight: 560, maxHeight: 600 }}
      />
      <Shapes />

      <ContainerStyled className="flex items-center relative my-auto">
        <div className="hidden md:block w-1/2 py-16">
          <img src={getPublicURL(`/theme/assets/images/site/portrait-frame.svg`)} alt="Lock frame" />
        </div>
        <div className="flex-1 py-12 md:py-24">
          <div className="mx-auto md:ml-auto md:mr-24 lg:mr-48 text-center md:text-left" style={{ maxWidth: 277 }}>
            <h1 className="leading-tight font-light text-4xl md:text-5xl">
              {t(data).SITE.LOCK.PORTRAIT.TITLE_1} <b className="font-bold">{t(data).SITE.LOCK.PORTRAIT.TITLE_2}</b>
              {t(data).SITE.LOCK.PORTRAIT.TITLE_3}
            </h1>
            <p className="mt-2 mb-6">{t(data).SITE.LOCK.PORTRAIT.DESCRIPTION}</p>
            <Button
              color={'primary'}
              onClick={action}
              className="px-12 py-4 leading-none transition-colors duration-200 hover:bg-secondary-100"
            >
              {t(data).SITE.LOCK.PORTRAIT.BUTTON}
            </Button>
          </div>
        </div>
      </ContainerStyled>
    </div>
  );
};
