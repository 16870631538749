import React, { useContext, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { uniqueId } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import customer from '../../../constants/customer.json';
import { CompanyContext } from '../../../context/company.context';
import creditCardService, { SiteLockErrorCode, SiteLockParam } from '../../../services/endpoints';

import { ContainerStyled } from '../styles';
import { Stepper } from '../stepper';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import Button from '../../button/button';

const PHONE_NUMBER_MIN_LENGTH = 10;
const PHONE_NUMBER_MAX_LENGTH = 15;
const ONLY_NUMBERS_REGEX = /^\d*$/;

type FormMode = 'lock' | 'unlock';

interface FormModeData {
  command: SiteLockParam;
  title: string;
  successMessage: string;
  successSvg: string;
  failedMessage: string;
  failedSvg: string;
}

interface FormStepperProps {
  items: { icon: string; className: string }[];
  currentIndex: number;
}

const FormStepper = ({ items, currentIndex }: FormStepperProps) => {
  const StepperStep = ({ className, icon }: { className: string; icon: string }) => (
    <div className={['w-16 h-16 md:w-20 md:h-20 rounded-full flex items-center justify-center', className].join(' ')}>
      <img src={icon} alt="icon" className="w-12 md:w-16 p-1" />
    </div>
  );

  return (
    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-3/5">
      <Stepper steps={items.map(StepperStep)} currentIndex={currentIndex} />;
    </div>
  );
};

interface FormView1Props {
  data: {};
  action: (phone: string) => void;
  phoneNumber: string;
}

const FormView1 = ({ action, phoneNumber, data }: FormView1Props) => {
  const { t } = useContext(LangContext);
  const formik = useFormik({
    initialValues: { phone: phoneNumber },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      phone: Yup.string()
        .min(PHONE_NUMBER_MIN_LENGTH, t(data).SITE.LOCK.FORM.STEP_01.VALIDATION.MIN_LENGTH)
        .matches(ONLY_NUMBERS_REGEX, t(data).SITE.LOCK.FORM.STEP_01.VALIDATION.MIN_LENGTH)
        .required(t(data).SITE.LOCK.FORM.STEP_01.VALIDATION.MIN_LENGTH),
    }),
    onSubmit(values) {
      action(values.phone);
    },
  });

  return (
    <div>
      <h3 className="text-xl text-primary mb-3">{t(data).SITE.LOCK.FORM.STEP_01.TITLE}</h3>
      <p className="text-xl text-primary-900 mb-4">
        {t(data).SITE.LOCK.FORM.STEP_01.MESSAGE_1} <b>{t(data).SITE.LOCK.FORM.STEP_01.MESSAGE_2}</b>
      </p>
      <label className="block text-gray-800 mb-3">{t(data).SITE.LOCK.FORM.STEP_01.LABEL}</label>
      <input
        value={formik.values.phone}
        onChange={event => formik.setFieldValue('phone', event.target.value)}
        onKeyPress={e => !ONLY_NUMBERS_REGEX.test(e.key) && e.preventDefault()}
        onKeyUp={event => event.key === 'Enter' && formik.submitForm()}
        className="block w-full h-12 border px-2 mb-2 rounded-sm"
        maxLength={PHONE_NUMBER_MAX_LENGTH}
        placeholder={`${t(data).EXAMPLE} ${customer.PhoneFormat.CellPhoneFormat}`}
        inputMode="numeric"
      />
      {formik.errors.phone && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik.errors.phone}
        </p>
      )}
      <Button color={'primary'} onClick={formik.submitForm} className="block h-12">
        {t(data).SITE.LOCK.FORM.STEP_01.BUTTON}
      </Button>
    </div>
  );
};

interface FormView2Props {
  data: {};
  action: (pin: string) => void;
}

const FormView2 = ({ action, data }: FormView2Props) => {
  const { t } = useContext(LangContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showValue, setShowValue] = useState(false);

  const formik = useFormik({
    initialValues: { pin: '' },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .min(4, t(data).SITE.LOCK.FORM.STEP_02.VALIDATION.MIN_LENGTH)
        .matches(ONLY_NUMBERS_REGEX, t(data).SITE.LOCK.FORM.STEP_02.VALIDATION.MIN_LENGTH)
        .required(t(data).SITE.LOCK.FORM.STEP_02.VALIDATION.MIN_LENGTH),
    }),
    onSubmit(values) {
      action(values.pin);
    },
  });

  const focusInput = () => inputRef.current!.focus();

  useEffect(focusInput, []);

  return (
    <div>
      <h3 className="text-xl text-primary mb-3">{t(data).SITE.LOCK.FORM.STEP_02.TITLE}</h3>
      <p className="text-xl text-primary-900 mb-4">
        {t(data).SITE.LOCK.FORM.STEP_02.MESSAGE_1} <b>{t(data).SITE.LOCK.FORM.STEP_02.MESSAGE_2}</b>
      </p>
      <label className="block text-gray-800 mb-3">{t(data).SITE.LOCK.FORM.STEP_02.LABEL}</label>
      <div className="relative">
        <input
          ref={inputRef}
          value={formik.values.pin}
          onChange={event => formik.setFieldValue('pin', event.target.value)}
          onKeyPress={e => !ONLY_NUMBERS_REGEX.test(e.key) && e.preventDefault()}
          onKeyUp={event => event.key === 'Enter' && formik.submitForm()}
          className={['block w-full h-12 border px-2 mb-2 rounded-sm', formik.errors.pin && 'border-red'].join(' ')}
          maxLength={4}
          css={css`
            -webkit-text-security: ${showValue ? 'none' : 'disc'};
            &::-webkit-credentials-auto-fill-button {
              visibility: hidden;
            }
          `}
          inputMode="numeric"
        />
        <div
          className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-2 p-2 cursor-pointer"
          onClick={() => {
            setShowValue(!showValue);
            focusInput();
          }}
        >
          {showValue ? <i className="icon-eye-open" /> : <i className="icon-eye-stroke" />}
        </div>
      </div>
      {formik.errors.pin && (
        <p className="text-red text-sm">
          <span className="icon-exclamation-triangle mr-1" />
          {formik.errors.pin}
        </p>
      )}
      <Button
        color={'primary'}
        onClick={formik.submitForm}
        className="block w-full h-12 bg-primary text-primary-contrast rounded-full mt-3"
      >
        {t(data).SITE.LOCK.FORM.STEP_02.LABEL}
      </Button>
    </div>
  );
};

interface FormView3Props {
  action: () => void;
  error?: SiteLockErrorCode;
  dataMode: FormModeData;
  data: {};
}

const FormView3 = ({ action, error, dataMode, data }: FormView3Props) => {
  const { t } = useContext(LangContext);
  const { getPublicURL } = useContext(CompanyContext);
  if (error) {
    const defaultErrorData = {
      message: dataMode.failedMessage,
      image: dataMode.failedSvg,
    };

    const errorData =
      {
        FGS1304: {
          message: t(data).SITE.LOCK.FORM.STEP_03.FAILED.INVALID_PIN,
          image: getPublicURL(`/theme/assets/images/site/account-lock/invalid-pin.svg`),
        },
        default: defaultErrorData,
      }[error] || defaultErrorData;

    return (
      <div>
        <h3 className="text-xl text-primary mb-3">{t(data).SITE.LOCK.FORM.STEP_03.FAILED.TITLE}</h3>
        <p className="text-xl text-red mb-6" dangerouslySetInnerHTML={{ __html: errorData.message }} />
        <img src={errorData.image} alt="Lock error" className="mx-auto" />
        <Button
          color={'primary'}
          onClick={action}
          className="block w-full h-12 bg-primary text-primary-contrast rounded-full mt-4"
        >
          {t(data).SITE.LOCK.FORM.STEP_03.FAILED.BUTTON}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <h3 className="text-xl text-primary mb-3">{t(data).SITE.LOCK.FORM.STEP_03.SUCCESS.TITLE}</h3>
      <p className="text-xl text-primary-900 mb-6" dangerouslySetInnerHTML={{ __html: dataMode.successMessage }} />
      <img src={dataMode.successSvg} alt="Lock success" className="mx-auto" />
    </div>
  );
};

interface FormProps {
  id?: string;
  mode: FormMode;
  mounted?: () => void;
}

export const Form = ({ mode, id = uniqueId(), mounted }: FormProps) => {
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          SITE {
            LOCK {
              FORM {
                UNLOCK {
                  TITLE
                  SUCCESS
                  FAILED
                }
                LOCK {
                  TITLE
                  SUCCESS
                  FAILED
                }
                STEP_01 {
                  TITLE
                  MESSAGE_1
                  MESSAGE_2
                  LABEL
                  BUTTON
                  VALIDATION {
                    MIN_LENGTH
                  }
                }
                STEP_03 {
                  FAILED {
                    TITLE
                    BUTTON
                    INVALID_PIN
                    INVALID_PHONE
                  }
                  SUCCESS {
                    TITLE
                  }
                }
                STEP_02 {
                  TITLE
                  MESSAGE_1
                  MESSAGE_2
                  LABEL
                  BUTTON
                  VALIDATION {
                    MIN_LENGTH
                  }
                }
              }
              PORTRAIT {
                TITLE_1
                TITLE_2
                TITLE_3
                DESCRIPTION
                BUTTON
              }
              UNLOCK_BANNER {
                TITLE_1
                TITLE_2
                TITLE_3
                TITLE_4
                BUTTON
              }
            }
            TOPBAR {
              LINKS {
                HOME
                LOCK
              }
            }
          }
          EXAMPLE
        }
      }
    }
  `);
  const getModes = (get: (key: string) => string): { [key in FormMode]: FormModeData } => {
    const { getPublicURL } = useContext(CompanyContext);
    return {
      lock: {
        command: 'lock_account',
        title: t(data).SITE.LOCK.FORM.LOCK.TITLE,
        successMessage: t(data).SITE.LOCK.FORM.LOCK.SUCCESS,
        successSvg: getPublicURL(`/theme/assets/images/site/account-lock/lock-success.svg`),
        failedMessage: t(data).SITE.LOCK.FORM.LOCK.FAILED,
        failedSvg: getPublicURL(`/theme/assets/images/site/account-lock/lock-failed.svg`),
      },
      unlock: {
        command: 'unlock_account',
        title: t(data).SITE.LOCK.FORM.UNLOCK.TITLE,
        successMessage: t(data).SITE.LOCK.FORM.UNLOCK.SUCCESS,
        successSvg: getPublicURL(`/theme/assets/images/site/account-lock/unlock-success.svg`),
        failedMessage: t(data).SITE.LOCK.FORM.UNLOCK.FAILED,
        failedSvg: getPublicURL(`/theme/assets/images/site/account-lock/unlock-failed.svg`),
      },
    };
  };

  const dataMode = getModes(data)[mode];

  const { getPublicURL } = useContext(CompanyContext);

  const generateStepperItems = (index: number, error = false) => [
    { icon: getPublicURL(`/theme/assets/images/site/account-lock/stepper-mobile.svg`), className: 'bg-primary' },
    {
      icon: getPublicURL(`/theme/assets/images/site/account-lock/stepper-lock.svg`),
      className: index >= 1 ? 'bg-primary' : 'bg-gray-400',
    },
    {
      icon:
        index === 2 && error
          ? getPublicURL(`/theme/assets/images/site/account-lock/stepper-times.svg`)
          : getPublicURL(`/theme/assets/images/site/account-lock/stepper-check.svg`),
      className: index < 2 ? 'bg-gray-400' : error ? 'bg-red' : 'bg-primary',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [stepperItems, setStepperItems] = useState(generateStepperItems(currentIndex));
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState<SiteLockErrorCode>(null);

  const setStep = (index: number) => {
    setCurrentIndex(index);
    setStepperItems(generateStepperItems(index, !!error));
  };

  const formView1Action = (phone: string) => {
    setPhoneNumber(phone);
    setStep(1);
  };

  const formView2Action = async (securityPin: string) => {
    try {
      await creditCardService.patchSiteLockAccount(phoneNumber, securityPin, dataMode.command);
    } catch ({ code }) {
      setError(code);
    }
    setPhoneNumber('');
    setStep(2);
  };

  const formView3Action = () => {
    setStep(0);
    setError(null);
  };

  const views = [
    <FormView1 data={data} action={formView1Action} phoneNumber={phoneNumber} />,
    <FormView2 data={data} action={formView2Action} />,
    <FormView3 data={data} action={formView3Action} error={error} dataMode={dataMode} />,
  ];

  useEffect(() => mounted?.(), []);

  return (
    <ContainerStyled id={id} className="relative mb-5 md:mb-16">
      <div className="w-full max-w-md mx-auto">
        <h2
          className="text-4xl text-center mb-20 text-primary-900"
          dangerouslySetInnerHTML={{ __html: dataMode.title }}
        />

        <div className="relative shadow-md rounded-lg px-5 md:px-10 pt-20 pb-5 md:pb-12">
          <FormStepper currentIndex={currentIndex} items={stepperItems} />

          {views[currentIndex]}
        </div>
      </div>
    </ContainerStyled>
  );
};
