import React, { useState } from 'react';
import Layout from '../../../components/site/layout';
import { Portrait } from '../../../components/site/account-lock/portrait';
import { Form } from '../../../components/site/account-lock/form';
import { UnlockBanner } from '../../../components/site/account-lock/unlock-banner';

const FORM_LOCK_ID = 'FORM_LOCK_ID';
const FORM_UNLOCK_ID = 'FORM_UNLOCK_ID';

const PublicLockPage = ({ get }: PageProps) => {
  const [showFormUnlock, setShowFormUnlock] = useState(false);

  const scrollToId = (id: string, offset: number) => {
    scrollTo({
      top: document.getElementById(id)!.offsetTop + offset,
      behavior: 'smooth',
    });
  };

  const goToFormLock = () => scrollToId(FORM_LOCK_ID, -100);
  const goToFormUnlock = () => (!showFormUnlock ? setShowFormUnlock(true) : scrollToId(FORM_UNLOCK_ID, -100));

  return (
    <Layout get={get} topbarSticky>
      <Portrait action={goToFormLock} />
      <Form  mode="lock" id={FORM_LOCK_ID} />
      <UnlockBanner action={goToFormUnlock} />
      {showFormUnlock && <Form  mode="unlock" id={FORM_UNLOCK_ID} mounted={goToFormUnlock} />}
      <div className="mb-16" />
    </Layout>
  );
};

export default PublicLockPage;
