import React from 'react';
import { min } from 'lodash';

interface StepperProps {
  steps: React.ReactNode[];
  currentIndex: number;
}

export const Stepper = ({ steps, currentIndex }: StepperProps) => {
  const segmentWidth = steps.length > 1 ? 100 / (steps.length - 1) : 0;
  const fillWidth = min([currentIndex * segmentWidth + segmentWidth / 2, 100]);

  return (
    <div className="h-1 relative bg-gray-400 text-primary-contrast">
      <div className="h-1 bg-primary" style={{ width: fillWidth + '%' }} />
      {steps.map((step, index) => (
        <div
          key={index}
          className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          style={{ left: index * segmentWidth + '%' }}
        >
          {step}
        </div>
      ))}
    </div>
  );
};
